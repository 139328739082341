import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Beron is a `}<a parentName="p" {...{
        "href": "/Acronist#farseeker",
        "title": "Acronist"
      }}>{`Acronist`}</a>{` who works the `}<a parentName="p" {...{
        "href": "/Grav-rail",
        "title": "Grav-rail"
      }}>{`Grav-rail`}</a>{` in `}<a parentName="p" {...{
        "href": "/Meripol",
        "title": "Meripol"
      }}>{`Meripol`}</a>{` for the `}<a parentName="p" {...{
        "href": "/Prisma%20Travel%20Corporation",
        "title": "Prisma Travel Corporation"
      }}>{`Prisma Travel Corporation`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      